<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <!-- <h3>Basic Details</h3> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-card class="mb-6">
          <v-toolbar flat dark dense :color="appColor">
            <v-toolbar-title>Basic Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="openEditCustomer(customer)"
                >
                  <v-icon left>mdi-pencil</v-icon>Edit
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Company</th>
                  <td>{{ customer.name }}</td>
                </tr>
                <tr>
                  <th>Website</th>
                  <td>{{ customer.website }}</td>
                </tr>
                <tr>
                  <th>Relationship</th>
                  <td>{{ customer.relationship }}</td>
                </tr>
                <tr>
                  <th>Company Email</th>
                  <td>{{ customer.company_email }}</td>
                </tr>
                <tr>
                  <th>Sector</th>
                  <td>{{ getSectorName(customer.sector) }}</td>
                </tr>
                <tr>
                  <th>Vat Number</th>
                  <td>{{ customer.vat_number }}</td>
                </tr>
                <tr>
                  <th>Shortcode</th>
                  <td>{{ customer.shortcode }}</td>
                </tr>
                <tr>
                  <th>Notes</th>
                  <td style="white-space: pre">{{ customer.notes }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-col cols="7">
        <v-card class="mb-6">
          <v-toolbar flat dark dense :color="appColor">
            <v-toolbar-title>Contacts</v-toolbar-title>
            <v-spacer> </v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="addContactForm.dialog = true"
                >
                  <v-icon left>mdi-plus</v-icon>Add Contact
                </v-btn>
              </template>
              <span>Add</span>
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table
            :headers="tableHeaders"
            :items="customer.contacts"
            show-expand
            no-data-text="No contacts found"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="openEditContact(item.id)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ item }">
              <div class="pa-4">
                <h4>Notes</h4>
                <div>{{ item.notes }}</div>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editCustomerForm.dialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Customer</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pt-5 grey lighten-4">
          <v-form @submit.prevent="editCustomer" method="post">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Customer Name *"
                  type="text"
                  v-model="editCustomerForm.name"
                  background-color="white"
                  outlined
                  dense
                  :error="editCustomerForm.errors.hasOwnProperty('name')"
                  :error-messages="editCustomerForm.errors['name']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Website"
                  type="text"
                  v-model="editCustomerForm.website"
                  background-color="white"
                  outlined
                  dense
                  :error="editCustomerForm.errors.hasOwnProperty('website')"
                  :error-messages="editCustomerForm.errors['website']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  :items="sectors"
                  label="Sector"
                  v-model="editCustomerForm.sector"
                  item-text="name"
                  :return-object="false"
                  chips
                  background-color="white"
                  outlined
                  dense
                  autocomplete="false"
                  :error="editCustomerForm.errors.hasOwnProperty('sector')"
                  :error-messages="editCustomerForm.errors['sector']"
                ></v-combobox>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="VAT Number"
                  type="text"
                  v-model="editCustomerForm.vat_number"
                  background-color="white"
                  outlined
                  dense
                  :error="editCustomerForm.errors.hasOwnProperty('vat_number')"
                  :error-messages="editCustomerForm.errors['vat_number']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Relationship"
                  :items="companyRelationships"
                  v-model="editCustomerForm.relationship"
                  item-text="label"
                  item-value="value"
                  background-color="white"
                  outlined
                  dense
                  :error="
                    editCustomerForm.errors.hasOwnProperty('relationship')
                  "
                  :error-messages="editCustomerForm.errors['relationship']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Short Code"
                  type="text"
                  v-model="editCustomerForm.shortcode"
                  background-color="white"
                  outlined
                  dense
                  class="shortcode-input"
                  :error="editCustomerForm.errors.hasOwnProperty('shortcode')"
                  :error-messages="editCustomerForm.errors['shortcode']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Company Email"
                  type="text"
                  v-model="editCustomerForm.company_email"
                  background-color="white"
                  outlined
                  dense
                  class
                  :error="
                    editCustomerForm.errors.hasOwnProperty('company_email')
                  "
                  :error-messages="editCustomerForm.errors['company_email']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  v-model="editCustomerForm.notes"
                  background-color="white"
                  outlined
                  dense
                  class
                  :error="editCustomerForm.errors.hasOwnProperty('notes')"
                  :error-messages="editCustomerForm.errors['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="editCustomerForm.dialog = false"
            >Close</v-btn
          >
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="editCustomerForm.loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addContactForm.dialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add New Contact</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pt-5 grey lighten-4">
          <v-form @submit.prevent="addContact" method="post" id="customer-form">
            <v-text-field
              label="Name *"
              type="text"
              v-model="addContactForm.name"
              background-color="white"
              outlined
              :error="addContactForm.errors.hasOwnProperty('name')"
              :error-messages="addContactForm.errors['name']"
            ></v-text-field>

            <v-text-field
              label="Email"
              type="email"
              v-model="addContactForm.email"
              background-color="white"
              outlined
              :error="addContactForm.errors.hasOwnProperty('email')"
              :error-messages="addContactForm.errors['email']"
            ></v-text-field>

            <v-text-field
              label="Phone Number"
              type="text"
              v-model="addContactForm.phone_number"
              background-color="white"
              outlined
              dense
              class
              :error="addContactForm.errors.hasOwnProperty('phone_number')"
              :error-messages="addContactForm.errors['phone_number']"
            ></v-text-field>

            <v-textarea
              label="Notes"
              v-model="addContactForm.notes"
              background-color="white"
              outlined
              dense
              :error="addContactForm.errors.hasOwnProperty('notes')"
              :error-messages="addContactForm.errors['notes']"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="addContactForm.dialog = false"
            >Close</v-btn
          >
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="addContactForm.loading"
            form="customer-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editContactForm.dialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Customer Contact</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form @submit.prevent="editContact" method="post">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Customer Name *"
                  type="text"
                  v-model="editContactForm.name"
                  background-color="white"
                  outlined
                  dense
                  :error="editContactForm.errors.hasOwnProperty('name')"
                  :error-messages="editContactForm.errors['name']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Phone Number"
                  type="text"
                  v-model="editContactForm.phone_number"
                  background-color="white"
                  outlined
                  dense
                  :error="editContactForm.errors.hasOwnProperty('phone_number')"
                  :error-messages="editContactForm.errors['phone_number']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Company Email"
                  type="text"
                  v-model="editContactForm.email"
                  background-color="white"
                  outlined
                  dense
                  class
                  :error="editContactForm.errors.hasOwnProperty('email')"
                  :error-messages="editContactForm.errors['email']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  v-model="editContactForm.notes"
                  background-color="white"
                  outlined
                  dense
                  class
                  :error="editContactForm.errors.hasOwnProperty('notes')"
                  :error-messages="editContactForm.errors['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="editContactForm.dialog = false"
            >Close</v-btn
          >
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="editContactForm.loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
    appColor: {
      type: String,
    },
  },

  data() {
    return {
      editCustomerForm: {
        dialog: false,
        id: null,
        name: null,
        website: null,
        sector: null,
        vat_number: null,
        relationship: null,
        company_email: null,
        notes: null,
        shortcode: null,
        loading: false,
        errors: {},
      },
      companyRelationships: [
        { value: "partner", label: "Partner", color: "success" },
        { value: "support", label: "Support", color: "warning" },
        { value: "ad-hoc", label: "Ad-hoc", color: "blue white--text" },
        { value: "left-us", label: "Left Us", color: "default" },
        { value: "oob", label: "OOB", color: "black white--text" },
        { value: "na", label: "N/A", color: "error" },
      ],

      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Phone Number", value: "phone_number" },
        { text: "Email", value: "email" },
        { text: "Actions", value: "actions", align: "right" },
        { text: "", value: "data-table-expand" },
      ],
      addContactForm: {
        dialog: false,
        name: null,
        email: null,
        phone_number: null,
        notes: null,
        loading: false,
        errors: {},
      },
      editContactForm: {
        dialog: false,
        id: null,
        name: null,
        email: null,
        phone_number: null,
        notes: null,
        loading: false,
        errors: {},
      },

      relationshipUpdating: null,
    };
  },

  computed: {
    sectors() {
      return this.$store.state.customers["sectors"];
    },

    customerContacts() {
      let customerContacts = this.$store.state.customers["customer_contacts"];
      return customerContacts;
    },
  },

  methods: {
    addContact: function () {
      const customerId = this.$route.params.customerId;
      const appId = this.$route.params.id;
      const name = this.addContactForm.name;
      const phone_number = this.addContactForm.phone_number;
      const email = this.addContactForm.email;
      const notes = this.addContactForm.notes;

      this.addContactForm.loading = true;

      this.$store
        .dispatch("customers/addContact", {
          appId,
          name,
          email,
          phone_number,
          notes,
          customerId,
        })
        .then(() => {
          this.addContactForm = {
            dialog: false,
            name: null,
            email: null,
            phone_number: null,
            notes: null,
            customerId: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.addContactForm.errors = err.response.data.errors;
          this.addContactForm.loading = false;
        });
    },
    openEditContact: function (id) {
      const contact = this.customer.contacts.find((c) => c.id === id);

      this.editContactForm = {
        dialog: true,
        id: id,
        contactId: contact.id,
        name: contact.name,
        phone_number: contact.phone_number,
        email: contact.email,
        notes: contact.notes,
        loading: false,
        errors: {},
      };
    },

    getSectorName(customerSector) {
      if (customerSector === null || customerSector === "") {
        return null;
      }

      const sector = this.sectors.find((s) => s.id === customerSector);
      return sector.name;
    },

    openEditCustomer: function (customer) {
      const sector = this.sectors.find((s) => s.id === customer.sector);

      this.editCustomerForm = {
        dialog: true,
        id: customer.id,
        name: customer.name,
        website: customer.website,
        sector: customer.sector !== null ? [sector.name] : null,
        vat_number: customer.vat_number,
        relationship: customer.relationship,
        shortcode: customer.shortcode,
        company_email: customer.company_email,
        notes: customer.notes,
        loading: false,
        errors: {},
      };
    },
    editCustomer: function () {
      const appId = this.$route.params.id;
      const customerId = this.editCustomerForm.id;
      const name = this.editCustomerForm.name;
      const website = this.editCustomerForm.website;
      const sector = this.editCustomerForm.sector;
      const vat_number = this.editCustomerForm.vat_number;
      const relationship = this.editCustomerForm.relationship;
      const shortcode = this.editCustomerForm.shortcode;
      const company_email = this.editCustomerForm.company_email;
      const notes = this.editCustomerForm.notes;

      this.editCustomerForm.loading = true;

      this.$store
        .dispatch("customers/editCustomer", {
          appId,
          customerId,
          name,
          website,
          sector,
          vat_number,
          relationship,
          shortcode,
          company_email,
          notes,
        })
        .then(() => {
          this.editCustomerForm = {
            dialog: false,
            id: null,
            name: null,
            website: null,
            sector: null,
            vat_number: null,
            relationship: null,
            shortcode: null,
            company_email: null,
            notes: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.editCustomerForm.errors = err.response.data.errors;
          this.editCustomerForm.loading = false;
        });
    },

    editContact: function () {
      const appId = this.$route.params.id;
      const customerId = this.$route.params.customerId;
      const contactId = this.editContactForm.contactId;

      const name = this.editContactForm.name;
      const phone_number = this.editContactForm.phone_number;
      const email = this.editContactForm.email;
      const notes = this.editContactForm.notes;

      this.editContactForm.loading = true;

      this.$store
        .dispatch("customers/editContact", {
          appId,
          customerId,
          contactId,
          name,
          email,
          phone_number,
          notes,
        })
        .then(() => {
          this.editContactForm = {
            dialog: false,
            id: null,
            contactId: null,
            name: null,
            email: null,
            phone_number: null,
            notes: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.editContactForm.errors = err.response.data.errors;
          this.editContactForm.loading = false;
        });
    },
  },
};
</script>
